import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import PortalRestaurantSearchManager from "../GoogleVenueSearch/PortalRestaurantSearchManager";
import PortalRestaurantManager from "../VenueManager/VenueManager";
import UserManager from "../UsersManager/UserManager";
import ReportManager from "../ReportManager/ReportManager";
import UserProfile from "../UserProfile/UserProfile";
import { MatchesView } from "../Matches/MatchesView";
import "./HomeView.scss";
import { UserInfoViewProvider } from "../../context/UserInfoContext";
import { useTabData } from "../../context/TabContext";

const PortalHome = () => {
  // const [activeTab, setActiveTab] = useState("users");
  const { tab, setTab } = useTabData();

  const handleSelect = (key: any) => {
    setTab(key);
    console.log(`Key: ${tab}`);
  };

  return (
    <div className="home-view-wrapper">
      <UserInfoViewProvider>
        <Tabs activeKey={tab} onSelect={handleSelect} className="mb-3">
          <Tab eventKey="resturants-search" title="Resturant Search">
            <PortalRestaurantSearchManager />
          </Tab>
          <Tab eventKey="resturants" title="Resturant Manager">
            <PortalRestaurantManager />
          </Tab>
          <Tab eventKey="users" title="User Manager">
            <UserManager />
          </Tab>
          <Tab eventKey="matches" title="Matches">
            <MatchesView />
          </Tab>
          <Tab eventKey="reports" title="Report Manager">
            <ReportManager />
          </Tab>
          <Tab eventKey="user-info" title="User Info">
            <UserProfile />
          </Tab>
        </Tabs>
      </UserInfoViewProvider>
    </div>
  );
};

export default PortalHome;
