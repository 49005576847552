import { Box } from "@mui/material";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Base_Url } from "../../constants/constants";
import "./PortalRestaurantSearchManager.scss";

async function getRestaurants(query: any) {
  return fetch(
    `${Base_Url}/google/venues?${new URLSearchParams(query)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((data) => {
      console.log(data);
      if (data.success && data?.restaurants) {
        return data.restaurants;
      }
      throw data.errorMessage;
    })
    .catch((error) => {
      console.error(error);
    });
}

const PortalRestaurantSearch = ({ setResturants, updateRestaurants }: any) => {
  const [address, setAddress] = useState("");
  const [term, setTerm] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const restaurants = await getRestaurants({ address, term });
    setResturants(restaurants);
  };

  return (
    <Box>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formLocation">
          <div className="input-wrapper">
            <Box className="input">
              <Form.Label>Search Location (Required) </Form.Label>
              <Form.Control
                type="address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Form.Text className="text-muted">
                Make sure you enter a valid address / location
              </Form.Text>
            </Box>

            {/* <Box className="input">
              <Form.Label>Search By Name</Form.Label>
              <Form.Control
                type="string"
                placeholder="Name"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
              <Form.Text className="text-muted">
                Make sure you enter a valid address / location
              </Form.Text>
            </Box> */}
          </div>

          <div className="button">
            <Button variant="primary" type="submit" disabled={address == ""}>
              Search
            </Button>
          </div>

          <div className="button">
            <Button variant="primary" onClick={updateRestaurants}>
              Add Selected Restaurants
            </Button>
          </div>
        </Form.Group>
      </Form>
    </Box>
  );
};

export default PortalRestaurantSearch;
